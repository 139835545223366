.bgWrapper {
    display: flex;
    background-repeat: no-repeat;
    background-image: url('./images/wrapper.png');
    background-size: contain;

    .titleWrapper {
        text-align: left;
        margin-left: 60px;
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        min-height: 106px;

        .clubTitle {
            font-family: Avenir Next;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
        }

        .itemTitle {
            font-family: Avenir Next;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
        }
    }
}