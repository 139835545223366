@import "../common.scss";
@import "../../ScreenBreakPoint.scss";

.dateWrapper {
    display: flex;
    justify-content: center;
    @include breakpoint(medium) {
        justify-content: left;
    }

    .dateValue {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        @include breakpoint(small) {
            padding: 5px;
        }
        @include breakpoint(medium) {
            padding: 15px;
        }
        background-color: #fff;
        color: #000;
        font-family: Bebas Neue;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .dateTitle {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);

    }
    .dayWrapper {
        margin: 0px 20px 0px 10px !important;
        @include breakpoint(medium) {
            margin: 0px 40px 0px 0px !important;
        }
    }
    .secWrapper {
        margin: 0px 10px 0px 10px !important;
        @include breakpoint(medium) {
            margin: 0px 0px 0px 20px !important;
        }
    }
    .colonWrapper {
        font-size: 40px;
        @include breakpoint(medium) {
            font-size: 50px;
        }
    }
    .dateSection {
        margin: 0px 10px 0px 10px;
        @include breakpoint(medium) {
            margin: 0px 20px 0px 20px;
        }
    }
    .dateTitleWrapper {
        display: flex;
    }
}