@import '../common/common.scss';
@import '../ScreenBreakPoint.scss';

.wrapper {

    .logo {
        height: auto;
        width: 315px;
    }

    font-family: Avenir Next;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
    padding-top: 20px;
    margin: 0px $homepage-horizontal-padding 40px $homepage-horizontal-padding;
}

.settingsSection {
    display: flex;
    justify-content: center;
    align-items: center;
    .userSection {
        display: flex;
        justify-content: center;
        align-items: center;

        .collectionWrapper {
            position: relative;
            .collection {
                width: 59px;
                height: 59px;
                background: #fff;
                border: 1px solid #FFFFFF;
                border-radius: 35px;
                cursor: pointer;
            }
            .walletActionDropdownWrapper {
                position: absolute;
                top: 75px;
                right: -120px;
            }
            .imageWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                .metaMaskIcon {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                }

                .portisIcon {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                }
            }
        }

        .walletWrapper {
            .wallet {
                margin: 0px 0px 0px 10px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                color: #FFFFFF;
                text-decoration: none;
                outline: none;
                border: none;
                background: transparent;
                cursor: pointer;
            }
        }
    }

    .langSelect {
        margin-right: 20px;
        background-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        color: #fff;
        cursor: pointer;
    }

    .howItWorksButton {
        margin-right: 20px;
        background-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        color: #fff;
        cursor: pointer;
    }

    .faqButton {
        margin-right: 20px;
        background-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        color: #fff;
        cursor: pointer;
    }

    .ctaButtonWrapper {
        margin: 0px 0px 0px 20px;
    }

    .logout {
        color: #fff;
        font-family: Avenir Next;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
    }

    @include breakpoint(medium) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
