@import "../../ScreenBreakPoint.scss";
@import "../../common/common.scss";

.progressBar {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slotWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .slotOn {
            width: 37px;
            height: 37px;
            @include gradientBackground;
            .step {
                text-align: center;
                line-height: 37px;
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .slotOff {
            width: 37px;
            height: 37px;
            border: 2px solid rgba(255, 255, 255, 0.7);
            .step {
                text-align: center;
                line-height: 37px;
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .slotTitle {
            margin: 10px 0px 0px 0px;
            font-family: Avenir Next;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #FFFFFF;
            width: 400%;
        }
    }
    .progressLineOn {
        margin-bottom: 35px;
        height: 2px;
        width: 100%;
        background: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%);
    }
    .progressLineOff {
        margin-bottom: 35px;
        height: 2px;
        width: 100%;
        background: grey;
    }
}
