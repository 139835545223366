@import "../../common/common.scss";

.bgWrapper {
    margin: 0px 40px 80px 40px;
    .contentWrapper {
        .informationSection {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin: 80px 0px 80px 0px;

            .videoWrapper {
                position: relative;
                width: auto;
                margin: 0px 80px 0px 0px;
                @include gradientBorder();
                border: 2px solid #D964F0;
                .videoContent {
                    min-width: 320px;
                    height: auto;
                }
            }

            .informationWrapper {
                margin: 0px 0px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                text-align: left;

                .descriptionWrapper {
                    margin: 40px 0px 20px 0px;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $default-paragraph-font-size;
                    color: #FFFFFF;
                    width: 80%;
                }
                .titleWrapper {
                    display: flex;
                    align-items:flex-start;
                    text-align: left;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $default-paragraph-font-size;
                    color: #FFFFFF;
                    margin: 20px 0px 20px 0px;
                    line-height: 24px;
                    width: 80px;
                    white-space: nowrap;

                    .history{
                        padding: 0px 100px 0px 0px;
                        .line {
                            text-align: left;
                        }
                    }
                }
                .currentBidsWrapper {
                    .buyOrder {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: Avenir Next;
                        font-style: normal;
                        font-weight: bold;
                        font-size: $default-paragraph-font-size;
                        color: #FFFFFF;
                        text-align: left;

                        .bidTaker {
                            margin: 0px 20px 10px 0px;
                            min-width: 100px;
                        }
                        .bidPrice {
                            margin: 0px 20px 10px 0px;
                            min-width: 100px;
                            font-weight: 500;
                        }
                        .bidTime {
                            margin: 0px 20px 10px 0px;
                            min-width: 100px;
                            font-weight: 500;

                        }
                    }
                }
            }
        }
        .timePriceSection {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .endAfter {
                font-family: Avenir Next;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
            }
            .countdown {
                margin: 0px 0px 0px 20px;
            }
            .ctaWrapper {
                margin: 0px 2px 20px 40px;
            }
            .priceWrapper {
                margin: 0px 20px 0px 20px;
            }
        }
    }
}