@import '../common/common.scss';
@import '../ScreenBreakPoint.scss';

.wrapper {
    padding-top: 20px;
    background: #170022;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    @include breakpoint(medium) {
        font-size: 20px;
    }

    .logoSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 20px 0px;
        @include breakpoint(medium) {
            padding: 20px 0px 40px 0px;
        }
        .companyLogo {
            width: 180px;
            @include breakpoint(medium) {
                width: 226px;
            }
        }
    }

    .socialMediaSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 20px 0px;
        @include breakpoint(medium) {
            padding: 20px 0px 20px 0px;
        }
        .logoWrapper {
            margin: 0px 10px 0px 10px;
            @include breakpoint(medium) {
                margin: 0px 20px 0px 20px;
            }

            .logo {
                width: 32px;
                @include breakpoint(small) {
                    width: 48px;
                }
                @include breakpoint(medium) {
                    width: 64px;
                }
            }
        }
    }

    .policySection {
        .policyLink {
            text-decoration: none;
        }
        .policy {
            padding: 0px 10px 0px 10px;
            @include breakpoint(medium) {
                padding: 0px 20px 0px 20px;
            }
            text-decoration: none;
            color: #fff;
        }

        .policy:link {
            text-decoration: none;
            color: #fff;
        }
        .policy:visited {
            text-decoration: none;
            color: #fff;
        }
        .policy:hover {
            text-decoration: none;
            color: #fff;
        }
        padding: 20px 0px 40px 0px;
    }

    .copyRightSection {
        height: 65px;
        line-height: 65px;
        background: #34004D;
        backdrop-filter: blur(41px);
        font-size: 14px;
        @include breakpoint(small) {
            font-size: 18px;
        }
        @include breakpoint(medium) {
            font-size: 18px;
        }
        @include breakpoint(large) {
            font-size: 22px;
        }
    }
}