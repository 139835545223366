@import '../../../common/common.scss';
@import '../../../ScreenBreakPoint';

.bgWrapper {
    @include common();
    padding: 80px $homepage-horizontal-padding 80px $homepage-horizontal-padding;
    background-image: url('../images/biditemsection_bg.png');
    background-size: cover;
    box-sizing: border-box;
    min-width: $homepage-min-width;
    display: flex;
    justify-content: center;
    margin: 40px 0px 40px 0px;

    .wrapper {
        position: relative;
        padding: 5% 10% 5% 10%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
        display: flex;
        justify-content: flex-start;

        .contentWrapper {
            width: 100%;

            .logoParentWrapper {
                padding: 0px 0px 20px 0px;
            }

            .description {
                padding: 20px 0px 20px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: normal;
                font-size: $default-paragraph-font-size;
                text-align: left;
            }
        }

        .dateWrapper {
            display: flex;
            justify-content: center;
            @include breakpoint(medium) {
                justify-content: left;
            }
        }

        .ctaButtonWrapper {
            display: flex;
            align-items: center;
            padding: 20px 0px 20px 0px;
            text-align: left;
            .ctaButton{
                padding: 0px 20px 0px 0px;
            }
        }

        .howToWrapper {
            padding: 20px 0px 20px 0px;
        }

        .videoWrapper {
            margin: 0px 0px 0px 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            .videoContent {
                min-width: 360px;
                width: 20vw;
                height: auto;
                border: 2px solid #D964F0;
            }
        }
    }
}