@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.parentWrapper {
    .titleWrapper {
        margin: 100px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: $page-min-height;

        .title {
            width: 800px;
            margin: 0px 0px 60px 0px;
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 100px;
            line-height: 100px;
            text-align: center;
            @include gradientFont();
        }

        .bodyWrapper {
            display: flex;
            .sectionButtons{
                justify-content: start;
                .buttonWrapper {
                    padding: 0px 40px 20px 0px;
                }
            }

            .itemListWrapper {
                .cell {
                    display: flex;
                    .id { 
                        font-size: 22px;
                        text-align:center;
                        line-height: 30px;
                        width: 40px; 
                    }
                    .cellBody {
                        width: 800px;
                        .questionWrapper {
                            line-height: 30px;
                            display: flex;
                            justify-content: space-between;
                            .question {
                                font-size: 22px;
                                text-align: left;
                                font-weight: 600;
                            }
                            .plusButton {
                                font-size: 35px;
                                cursor: pointer;
                                text-align: center;
                                color: rgba(255, 255, 255, 0.7);
                                margin-top: -5px;
                            }
                        }
                        .answerWrapper{
                            // padding: 5px 0px 5px 0px;
                            .answer {
                                font-size: 18px;
                                text-align: left;
                                padding: 20px 40px 10px 5px;
                                color: rgba(255, 255, 255, 0.7);
                            }
                            .line{
                                border-color: rgba(255, 255, 255, 0.3);
                            }
                        }
                    }
                }
            }
        }   
    }
}