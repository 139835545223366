@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.bgWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    min-width: $page-min-width;
    height: 100vh;
    min-height: $page-min-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(15, 0, 22, 0.7);
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);

    .parentWrapper {
        position: relative;
        background-repeat: no-repeat;
        background-color: #1F002E;;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
        padding: 0px 80px 50px 80px;

        .closeButton {
            position: absolute;
            top: -40px;
            right: 0px;
            width: 70px;
            height: 35px;
            background: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            color: #fff;
            font-family: Avenir Next;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
        }

        .contentWrapper {

            .titleWrapper {
                display: flex;
                justify-content: center;
                padding: 50px 0px 20px 0px;
                .title {
                    font-family: Bebas Neue;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 80px;

                    text-align: center;
                    background: linear-gradient(91.85deg, #D964F0 26.3%, #45F6FC 94.43%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    height: 100px;
                }
            }

            .walletWrapper {
                display: flex;
                justify-content: center;
                padding: 20px 0px 0px 0px;

                .walletSection {
                    width: 342px;
                    height: 420px;
                    border-radius: 8px;
                    background: #fff;
                    color: #000;
                    margin: 0px 20px 0px 20px;

                    .logoWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 0px 20px 0px;
                        min-height: 140px;
                        .metaMaskLogo {
                            width: auto;
                            height: 96px;
                        }
                        .portisLogo {
                            width: auto;
                            height: 128px;
                        }
                    }

                    .description {
                        min-height: 40px;
                        margin: 0% 10% 0% 10%;
                        font-family: Avenir;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        padding: 20px 0px 20px 0px;
                    }

                    .loadingIconWrapper {
                        padding: 0px 0px 20px 0px;
                    }

                    .ctaButtonWrapper {
                        padding: 0px 20px 20px 0px;
                    }

                    .createWallet {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 0px 20px 0px;
                        font-family: Avenir Next;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;

                        .howIcon {
                            padding: 0px 5px 0px 0px;
                            cursor: pointer;
                        }

                        .createWalletLink {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .createWalletText {
                                margin: 0px 0px 0px 10px;
                            }

                            .createButton {
                                font-family: Avenir Next;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                color: purple;
                                padding: 0px 0px 0px 5px;
                                outline: none;
                                border: none;
                                cursor: pointer;
                                background: none;
                            }

                        }

                    }
                }
            }

            .howToConnectWrapper {
                padding: 40px 0px 0px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}

.loadingIcon {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}