@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.wrapper {
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    .detail {
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
    }
    .terms {
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
    }
}
