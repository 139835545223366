@import "../../../ScreenBreakPoint.scss";
@import "../../../common/common.scss";

$stepVerticalLargeMargin: 20px;
$stepHorizontalLargeMargin: 40px;

.bgWrapper {
    position: relative;
    min-height: 1000px;
    background-image: url("./images/HowItWorks_Bg.png");
    padding: 40px $homepage-horizontal-padding 40px $homepage-horizontal-padding;

    .contentWrapper {
        top: 300px;

        .titleSection {
            text-align: left;
            .part1 {
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
            }

            .part2 {
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                @include gradientFont();
                font-size: 64px;
                width: 450px;
            }
        }

        .stepSection {
            background: none;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .bgLine {
                z-index: 0;
                position: absolute;
                top: 300px;
                max-width: 827px;
            }

            .stepImageWrapper {
                margin: 0px 0px 40px 0px;
                .stepImage {
                    max-width: 250px;
                }
            }

            .step1Wrapper {
                z-index: 1;
                margin: $stepVerticalLargeMargin*3 $stepHorizontalLargeMargin*2 0px 0px;
                width: 400px;
            }

            .step2Wrapper {
                z-index: 1;
                margin: $stepVerticalLargeMargin*2 $stepHorizontalLargeMargin*2 0px $stepHorizontalLargeMargin*2;
                width: 400px;
            }

            .step3Wrapper {
                z-index: 1;
                margin: $stepVerticalLargeMargin*1 0px 0px $stepHorizontalLargeMargin*2;
                width: 400px;
            }

            .descriptionWrapper {
                .stepTitle {
                    display: inline-block;
                    margin: 0px 0px 20px 0px;
                    text-align: left;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                }

                .stepDescription {
                    text-align: center;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                }
            }
        }

        .howToWrapper{
            margin: 20px 0px 20px 0px;
            text-align: center;
            font-family: Avenir Next;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
        }
    }
}