@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import "./common/common.scss";
@import "./ScreenBreakPoint.scss";

.app {
  text-align: center;
}

.appHeader {
  background-color: #170022;
  min-height: 100vh;
  min-width: $page-min-width;
  min-height: $page-min-height;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.description2 {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: $default-paragraph-font-size;
  line-height: 200%;
  padding: 0 $homepage-horizontal-padding 0% $homepage-horizontal-padding;
  margin: 40px 0px 40px 0px;
  text-align: center;
}

.description3 {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: $default-paragraph-font-size;
  line-height: 200%;
  padding: 0 $homepage-horizontal-padding 0% $homepage-horizontal-padding;
  margin: 40px 0px 40px 0px;
  text-align: center;
}

.popupBgWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(15, 0, 22, 0.7);
  backdrop-filter: blur(21px);
  -webkit-backdrop-filter: blur(21px);
}

.bgWrapper {
  min-width: $page-min-width;
  min-height: $page-min-height;
  background-repeat: no-repeat;
  background-color: #170022;
  background-image: url('./pages/homepage/images/background.png');
}