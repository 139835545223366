.bgWrapper {
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        line-height: 27px;
        color: #FFFFFF;

        .selection {
            width: 286px;
            height: 57px;
            font-family: Avenir Next;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            border-radius: 2px;
            cursor: pointer;
            z-index: 5;
        }

        .firstSelection {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            z-index: 5;
        }
        .lastSelection {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            z-index: 5;
        }
        .selection:hover {
            background: #DA65F1;
            font-family: Avenir Next;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #FFFFFF;
        }
    }
}