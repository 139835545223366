@import '../../common/common.scss';
@import '../../ScreenBreakPoint.scss';

$sectionMargin: 25px;

.bgWrapper {
    position: relative;
    color: #FFFFFF;
    text-align: left;
    position: relative;
    min-width: $homepage-min-width;
    min-height: 968px;
    background-repeat: no-repeat;
    background-color: #170022;
    background-image: url('./images/background.png');

    .contentWrapper {
        display: flex;
        align-items: center;
        padding: 80px $homepage-horizontal-padding 80px $homepage-horizontal-padding;

        .players {
            z-index: 1;
            display: block;
            position: absolute;
            min-width: 600px;
            width: 60vw;
            right: 0px;
        }

        .contentSection {
            padding-bottom: 20px;
            z-index: 2;

            .title_zh_HK {
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 64px;
                line-height: 90px;

                .part2 {
                    background: linear-gradient(91.85deg, #D964F0 26.3%, #45F6FC 94.43%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 80px;
                }
            }

            .title {
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 80px;

                .part2 {
                    background: linear-gradient(91.85deg, #D964F0 26.3%, #45F6FC 94.43%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 100px;
                    line-height: 100px;
                }
            }

            .description {
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 176.6%;
                margin: $sectionMargin 0px $sectionMargin 0px;
                font-size: 20px;

                .partnerSection {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 40px 0px 40px 0px;

                    .partnerDescription {
                        font-family: Avenir Next;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 176.6%;
                        margin-right: 20px;
                    }
                }
            }

            .ctaButtons {
                display: flex;
                align-items:center;
            }

            .ctaButtonWrapper {
                padding: 20px 20px 20px 0px;
            }
        }
    }


}