@import '../ScreenBreakPoint.scss';

.wrapper {
    text-align: left;
    font-size: 14px;
    margin: 0% 0% 0% 0%;

    @include breakpoint(medium) {
        margin: 0% 20% 0% 20%;
    }

    .title {
        font-size: 18px;
        margin: 10px 0px 10px 0px;
        text-align: center
    }

    .sectionTitle {
        font-size: 18px;
        margin: 10px 0px 10px 0px;
    }

    .underlined {
        text-decoration: underline;
    }

    .paragraph {
        font-size: 14px;
        margin: 10px 0px 10px 0px;
    }
}