@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.bgWrapper {

    @include popupBg();

    .idlePopupWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 60%;
        min-height: 450px;
        height: 60%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 100px;
                line-height: 100px;
            }

            .description {
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }

            .priceSection {
                .priceTitle {
                    margin: 10px 0px 10px 0px;
                    text-align: left;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                }
                .priceWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin: 20px 0px 20px 0px;
                    .priceIconWrapper {
                        .priceIcon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 113px;
                            height: 55px;
                            background: rgba(255, 255, 255, 0.2);
                            border-radius: 8px;
                            margin: 0px 10px 0px 0px;
                            .iconName {
                                margin: 0px 0px 0px 5px;
                                font-family: Avenir Next;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 20px;
                            }
                        }
                    }

                    .wethInput {
                        width: 358px;
                        height: 50px;
                        border-radius: 8px;
                        background: rgba(255, 255, 255, 0.2);
                        font-family: Avenir Next;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        color: #fff;
                        padding-left: 10px;
                    }
                    .wethInput::-webkit-outer-spin-button, .wethInput::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                   }
                }
            }

            .termsSection {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0px 20px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                .checkBox {
                    width: 20px;
                    height: 20px;
                    margin: 0px 5px 0px 0px;
                }
            }

            .ctaSection {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 25px 0px 0px 0px;
                /*
                .placeBid {
                    margin: 0px 20px 0px 0px;
                }*/
                .wrapEth {
                    margin: 0px 0px 0px 20px;
                }
            }

            .howToBidSection{
                display: flex;
                justify-content: center;
                padding: 30px;
            }
        }
    }

    .inProgress {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        width: 40%;
        min-height: 300px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 40px 40px 40px;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 45px;
            }

            .description {
                margin: 0px 0px 40px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;

            }
        }
    }

    .transactionDone {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        width: 40%;
        min-height: 300px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 40px 40px 40px;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 45px;
            }

            .description {
                margin: 0px 0px 40px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                .hash{
                    font-size: 18px;
                }
            }
        }
    }

    .progressFailed {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 40%;
        min-height: 450px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 40px 0px;
                @include gradientFont();
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 45px;
            }

            .description {
                margin: 0px 0px 40px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
            }

            .ctaSection {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0px 0px 0px;

                .tryAgain {
                    margin: 0px 20px 0px 0px;
                }
                .wrapEth {
                    margin: 0px 0px 0px 20px;
                }
            }
        }
    }
}